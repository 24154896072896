$size-site-small: 92rem;
$size-site: 146rem;
$size__site-main: 100%;
$size-sidebar: 26.8rem;

// Breakpoints in px
$tablet-portrait: 600px;
$tablet-landscape: 900px;
$desktop: 1200px;
$big-desktop: 1800px;
